
    import gsap from "~/mixins/gsap";
    export default {
        mixins : [gsap],
        data() {
            return {
                breadcrumbs : [],
                mainItems: null,
                timeoutHolder : null,
                menuIndex: 0,
                subMenuIndex: 0,
                categoryIndex: 0,
                tlIn : null,
                tlOut : null,
                menu : null,
                alreadyOnRequest : false
            }
        },
        computed : {
            menuOpen : {
                get(){
                    return this.$store.state.layout.menuOpen;
                },
                set(v){
                    this.$store.commit("layout/setMenuOpen",v)
                }
            }
        },
        watch : {
            $route : {
                deep : true,
                handler(){
                    this.menuOpen = false;
                    this.step = 0;
                }
            },
            menuOpen(){
                this.timeoutHolder = setTimeout(() => {
                    this.initMenu();
                }, 500);
            }
        },
        mounted() {
            this.getMenu();
        },
        beforeDestroy() {
            if(this.timeoutHolder) clearTimeout(this.timeoutHolder);
        },
        methods: {
            goBackTo(index){
                var arr = [];
                if(index >= 0){
                    for(var i in this.breadcrumbs){
                        if(i <= index) arr.push(this.breadcrumbs[i]);
                    }
                }
                this.breadcrumbs = arr;
                this.searchActiveStep(false);
            },
            changeStep(obj){
                this.breadcrumbs.push(obj.parent);
                this.searchActiveStep(true);
            },
            searchActiveStep(forward){
                if(!this.menu) return false;
                const items = this.breadcrumbs[this.breadcrumbs.length - 1] ? this.breadcrumbs[this.breadcrumbs.length - 1].children : this.menu.primaryLinks;
                if(items){
                    var sectionsToGoOut = this.$el.querySelectorAll('.menuSection:not(.sub)');
                    if(this.breadcrumbs.length == 1 && forward){
                        sectionsToGoOut = this.$el.querySelectorAll('.menuSection'); 
                    }

                    this.gsap.fromTo(sectionsToGoOut,{
                            x: 0,
                            opacity:1
                        },{
                            x: 10,
                            opacity: 0,
                            onComplete : () => {
                                this.mainItems = items;
                                this.$nextTick(() => {
                                    var sectionsToGetIn = this.$el.querySelectorAll('.menuSection:not(.sub)');
                                    if(this.breadcrumbs.length == 0){
                                        sectionsToGetIn = this.$el.querySelectorAll('.menuSection'); 
                                    }
                                    this.gsap.fromTo(sectionsToGetIn,{
                                                x: 10,
                                                opacity:0
                                            },{
                                                x: 0,
                                                opacity: 1,
                                                duration: 0.2,
                                            })

                                    var itemsToGetIn = this.$el.querySelectorAll('.menuSection .item');
                                    this.gsap.fromTo(itemsToGetIn,{
                                                x: 10,
                                                opacity:0
                                            },{
                                                stagger: 0.1,
                                                x: 0,
                                                opacity: 1,
                                                ease: "back.out(4)"
                                            })
                                    
                                })
                            }
                        })
                }
            },
            initMenu(){
                if(!this.menu) return false;
                this.breadcrumbs = [];
                this.mainItems = this.menu.primaryLinks;
                var sections = this.$el.querySelectorAll('.menuSection'); 
                this.gsap.set(sections,{ x: 0, opacity:1 })
            },
            getMenu(){
                if(!this.menu && !this.alreadyOnRequest){
                    this.alreadyOnRequest = true;
                    this.$axios.get("storyblok/stories/system/urbanmenu",{params:{flushcache : 'yes'}}).then(axRes => {
                        this.alreadyOnRequest = false;
                        this.menu = axRes.data.story.content;
                        this.initMenu(axRes.data.story.content);
                    }).catch(axErr => {
                        this.alreadyOnRequest = false;
                    })
                }
            },
        },
    }
